@media screen and (max-width: 767px) {
    .calendar-big {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .calendar-small {
        display: none;
    }
}

.calendar-full {
    padding-bottom: 5px;
}