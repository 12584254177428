.project-member-card {
    width: 200px;
    height: 180px;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    margin: 10px 20px 10px 0px;
    text-align: center;
    padding: 20px;
}

.project-member-card:hover {
    box-shadow: 10px 5px 5px #f1f1f1;
}

.custom-tag {
    color: black;
    height: 30px;
    border-radius: 100px;
    padding: 5px 10px;
    margin: 2px;
    font-size: 12px
}

.card {
    width: 300px;
    border: 1px solid #e1e1e1;
    border-radius: 24px;
    height: 100%;
    padding: 10px;
}

.card:hover {
    box-shadow: 10px 5px 5px #f1f1f1;
}

.card .card-header {
    height: 30px;
    background-color: #CAE9F6;
    border-radius: 24px 24px 0px 0px;
    border-right: 1px solid #e1e1e1;
    margin: -10px -11px 0px -11px;
}

.card .card-name {
    padding: 5px 0px;
    font-weight: bold;
}

.card .card-description {
    padding: 5px 0px;
    white-space: pre-wrap;
}

.card .card-info {
    padding: 5px 0px;
}

.card .card-actions {
    border-top: 1px solid #f1f1f1;
    padding: 10px 0px 0px 0px;
    text-align: center;
}

.card div.ant-typography {
    margin-bottom: 0px;
}