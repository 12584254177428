.gradeCompany .mainTitle {
    font-weight: bold;
    margin: 20px 0px 10px 0px;
}

.gradeCompany .panel {
    margin: 10px;
}

.gradeCompany .memberName {
    padding-top: 10px;
    width: 100%;
}

.gradeCompany .gradeTable td {
    border-bottom: 1px solid lightgray;
    padding: 2px 5px;
}

.gradeCompany .gradeTable td.right {
    text-align: right;
}

.gradeCompany .memberGrades {
    padding-left: 20px;
}

.gradeCompany .memberComments {
    padding-left: 20px;
}

.gradeCompany .userName {
    color: gray
}

.gradeCompany .self {
    color: purple
}

.gradeCompany div.ant-typography {
    margin-bottom: 6px;
}

.gradeCompany tr.footer td {
    font-weight: bold;
}