.entity .property {
    margin: 10px 0px
}

.entity .property .label {
    font-weight: bold;
}

.entity .property .value {
    white-space: pre-wrap;
}

.entity .card-block-header {
    font-weight: bold;
}