.site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 4px;
    margin: 0px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-content {
    padding: 0px;
    margin: 0px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
    margin: 0px;
}

.menu-icon {
    font-size: 20px;
    margin: 6px
}
