.protonus-params-panel {
    width: 320px;
    max-width: 320px;
    min-width: 320px;
    background-color: white;
    padding: 10px;
    border: 1px solid #b1b1b1;
    border-radius: 5px;
}

.protonus-params .protonus-add {
    color: #1890ff;
}

.protonus-params .protonus-up, .protonus-params .protonus-down, .protonus-params .protonus-remove, .protonus-params .protonus-sort {
    margin-left: 10px;
}